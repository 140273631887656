@import '~bootstrap/scss/bootstrap.scss';
@import '_variables.scss';
@import '_homepage.scss';
@import '_paket.scss';
@import '_vvippage.scss';
@import '_auth.scss';
@import '_profile.scss';
@import '_ilmu-saham.scss';
@import '_saham-today.scss';
@import '_verification.scss';

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// main body

body {
    font-family: 'Roboto', sans-serif;
    background: $base-color-white;
    color: $base-color-black;

    @media (min-width: 300px) and (max-width: 767px) {
        background-color: $base-color-soft-grey-1;
    }
}

header {
    .container {
        display: flex;
        width: 100%;
        height: 110px;
        align-items: center;
        justify-content: space-between;
        background-color: $base-color-white;
        padding: 0.25rem;

        .logo {
            display: block;
        }

        .logoMobile {
            display: none;
        }

        img {
            width: 200px;
        }
    
        .btnActionUser {
            display: flex;
            width: 100%;
            justify-content: flex-end;

            .btnSignin {
                width: 170px;
                color: $base-color-orange;
                border-radius: 5px;
                border: 1px solid $base-color-orange;
            }
    
            .btnRegister {
                width: 170px;
                color: $base-color-white;
                background-color: $base-color-orange;
                border: 1px solid $base-color-orange;
            }
        }

        .profile {
            color: $base-color-black-3;
            
            img {
                width: 50px;
                border-radius: 5px;
            }

            p {
                margin-bottom: 0;
            }

            a {
                color: $base-color-black-3;
                text-decoration: none;
            }

            .notifBell {
                color: $base-color-purple;
                margin-right: 1rem;
                cursor: pointer;
                position: relative;

                .redDot {
                    width: 10px;
                    height: 10px;
                    background-color: red;
                    border-radius: 100%;
                    position: absolute;
                    top: 0;
                    right: 2px;
                }
            }
        }

        @media (min-width: 300px) and (max-width: 767px) {
            top: 0;
            z-index: 3;
            height: 80px;
            padding: 1rem;
            position: relative;
            color: $base-color-white;
            background-color: $base-color-soft-orange;
    
            .menuMobile {
                border: none;
                font-size: 24px;
                color: $base-color-white;
                background-color: transparent;
                position: absolute;
                top: 25px;
                left: 15px;
            }

            .logo {
                display: none;
            }

            .logoMobile {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 135px;
                }
            }
    
            .notificationMobile {
                font-size: 20px;
                position: absolute;
                top: 25px;
                right: 15px;

                .redDot {
                    width: 10px;
                    height: 10px;
                    background-color: red;
                    border-radius: 100%;
                    position: absolute;
                    top: 0;
                    right: 2px;
                }

                #popover-contained-mobile {
                    min-width: 280px;

                    .popover-body {
                        padding: 0;
                        max-height: 400px;
                        overflow: scroll;
                    }

                    .groupByDate {
                        padding: 10px;
                        background-color: $base-color-dark-grey-5;
                    }

                    .innerBody {

                        a {
                            color: $base-color-black-2;

                            .detailNotif {
                                display: flex;
                                align-items: center;
                                padding: 10px;
                                border-bottom: 1px solid $base-color-dark-grey-5;

                                .detailNotifText {
                                    margin-left: 10px;

                                    p {
                                        margin-bottom: 0;
                                    }
                                }

                                img {
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 100%;
                                }
                            }
                        }

                    }
                }
            }

            .btnActionUser {
                display: none;
            }
        }
    }
}

.menuNav {
    width: 100%;
    text-align: center;
    color: $base-color-white;
    background-color: $base-color-purple;

    a {
        color: $base-color-white;
        font-weight: 700;
        text-decoration: none;
    }
    a.selected {
        color: $base-color-white;
        background-color: $base-color-purple-2;
        transition: 0.2s;
    }
    a:hover {
        color: $base-color-white;
        background-color: $base-color-purple-2;
        transition: 0.2s;
    }

    @media (min-width: 300px) and (max-width: 767px) {
        display: none;
    }
}

.mainContent {
    width: 100%;
    min-height: calc(100vh - 200px);
}

.breadcrumb {
    background: transparent;
    padding: 10px 0px;
    list-style: none;

    li {
        display: inline;
        font-size: 14px;
    }

    li+li:before {
        padding: 8px;
        color: $base-color-dark-grey-1;
        content: ">";
    }

    li a {
        color: $base-color-dark-grey;
        text-decoration: none;
    }

    li a:hover {
        color: $base-color-dark-grey-1;
        text-decoration: underline;
    }
}

.breadcrumbMobile {
    display: none;
    width: 100%;
    height: 55px;
    padding: 1rem;
    align-items: center;
    color: $base-color-white;
    background-color: $base-color-purple-2;

    .title {
        font-size: 18px;
        margin-left: 10px;
        margin-bottom: 0;
    }

    @media (min-width: 300px) and (max-width: 767px) {
        display: flex;
    }
}

#popover-contained {
    min-width: 250px;

    .popover-body {
        padding: 0;
        max-height: 400px;
        overflow: scroll;
    }

    .groupByDate {
        padding: 10px;
        background-color: $base-color-dark-grey-5;
    }

    .innerBody {

        .detailNotif {
            display: flex;
            align-items: center;
            padding: 10px;
            border-bottom: 1px solid $base-color-dark-grey-5;

            .detailNotifText {
                margin-left: 10px;
            }

            img {
                width: 40px;
                height: 40px;
                border-radius: 100%;
            }
        }
    }

    .arrow::after {
        border-bottom-color: $base-color-orange !important;
    }
}

.popover-header {
    color: $base-color-white;
    background-color: $base-color-orange;
}

.popover-header::before {
    border-bottom-color: $base-color-orange !important;
}

#popover-contained-package {
    padding: 10px;
}

.sideMenu {
    z-index: 1;
    opacity: 0;
    width: 100%;
    height: 100%;
    left: -100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.35);
    -webkit-transition: .15s ease-in-out;
    transition: .15s ease-in-out;

    .sideMenuNav {
        z-index: 2;
        width: 250px;
        height: 100%;
        left: -250px;
        position: fixed;
        padding-top: 2rem;
        padding-left: 1rem;
        background-color: $base-color-dark-grey;
        -webkit-transition: .35s ease-in;
        transition: .35s ease-in;

        ul {
            padding: 0;

            li {
                font-size: 20px;
                color: $base-color-white;
                text-align: left;
                list-style-type: none;
                word-spacing: 1;
                margin-bottom: 1rem;
                
                a {
                    font-weight: 400;
                    font-style: normal;
                    text-decoration: none;
                    margin-left: 5px;
                    color: $base-color-white;
                }

                .btnLogout {
                    width: 100%;
                    padding: 0;
                    font-weight: 400;
                    font-style: normal;
                    color: $base-color-white;
                    background-color: transparent;
                    border: none;
                    text-align: left;
                }
            }
        }
    }
}

.modal {
    display: none; 
    position: fixed; 
    z-index: 1; 
    padding-top: 110px; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.35);

    .modal-content {
      position: relative;
      background-color: #fefefe;
      margin: auto;
      padding: 0;
      width: 580px;
      border: none;
      border-radius: 0px;
      -webkit-animation-name: animatetop;
      -webkit-animation-duration: 0.4s;
      animation-name: animatetop;
      animation-duration: 0.4s;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    }

    .close {
        color: white;
        font-size: 28px;
        font-weight: bold;
        position: absolute;
        right: 20px;
    }
    
    .close:hover,
    .close:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    }
    
    .modal-header {
        position: relative;
        padding: 10px 16px;
        border-radius: 0px;
        justify-content: center;
        color: $base-color-white;
        background-color: $base-color-purple;
    }
    
    .modal-body {
        padding: 20px;
    }

    @media (min-width: 300px) and (max-width: 768px) {
        .modal-content {
            width: 80%;
        }
    }
  }
  
  @-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
  }
  
  @keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }

.hr-two-tone {
    &:before {
        content: '';
        display: block;
        border-bottom: 2px solid $base-color-orange;
        margin-bottom: -2px;
        max-width: 200px;
    }

    @media (min-width: 300px) and (max-width: 767px) {
        display: none;
    }
}

.noInternet,
.notFound {
    min-height: calc(100vh - 200px);

    .row {
        justify-content: center;
    
        img {
            width: 100%;
        }
    }

    @media (min-width: 300px) and (max-width: 767px) {
        padding: 1rem;
    }
}

.modal-backdrop {
    z-index: auto;
}

.footerMobile {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70px;
    padding: 0.5rem;
    text-align: center;
    background-color: $base-color-dark-black;
    box-shadow: 0px -1px 15px rgba(0, 0, 0, 0.2);

    a {
        width: 100%;

        .footerMobileLink {
            svg {
                fill: $base-color-orange-2;
            }
            color: $base-color-orange-2;
        }
    }

    a.active {
        color: $base-color-orange;
        fill: $base-color-orange;

        .footerMobileLink {
            svg {
                fill: $base-color-orange;
            }
            color: $base-color-orange;
        }
    }

    .footerMobileLink {
        width: 100%;
        border: none;
        padding: 0;
        background-color: transparent;
        color: $base-color-gold;

        img {
            width: 25px;
            height: 25px;
        }

        p {
            margin: 0;
            font-size: 10px;
        }

    }
    .footerMobileLink.active {
        svg {
            fill: $base-color-orange;
        }
        color: $base-color-orange;
    }
    .footerMobileLink:hover {
        color: $base-color-orange;
    }
}

.dimmer {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.3);
    min-height: calc(90vh - 100px);
}

footer {
    color: $base-color-white;
    background-color: $base-color-dark-grey;

    ul {
        padding: 0px;
        list-style-type: none;

        li {
            line-height: 2.5;
            a {
                color: $base-color-white;
                text-decoration: none;
            }
        }
    }

    .socialMedia {
        img {
            width: 48px;
        }
    }

    .officialApp {
        img {
            width: 200px;
        }
    }

    .copyright {
        font-weight: bolder;
    }

    @media (min-width: 300px) and (max-width: 767px) {
        display: none;
    }
}

.header-popup{
    background-color: #282B50;
    color: white;
}