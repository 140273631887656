@import '_variables.scss';

.homepage {
    padding-top: 3rem;
    padding-bottom: 3rem;

    .memberCard {
        .memberCardHeader {
            border-radius: 5px;
            border-left: 15px solid $base-color-purple;

            h3 {
                margin-left: 10px;
            }
        }

        .memberCardBody {
            position: relative;
            width: 100%;
            min-height: 180px;
            border-radius: 5px;
            margin-top: 1rem;
            padding: 1rem;
            color: $base-color-white;
            background-color: $base-color-purple;

            img {
                border-radius: 5px;
            }

            .vipImage {
                position: absolute;
                right: 20px;
                width: 55px;
                height: 55px;
            }

        }

        @media (min-width: 300px) and (max-width: 767px) {
            .memberCardBody {
                min-height: 120px;
                margin-top: 0px;

                h3 {
                    font-size: 18px;
                }

                p {
                    font-size: 14px;
                    margin-bottom: 0px;
                }

                .vipImage {
                    width: 33px;
                    height: 33px;
                    right: 10px;
                }
            }
        }

        @media (min-width: 767px) and (max-width: 1024px) {
            .memberCardBody {
                height: auto;
                margin-top: 0px;

                h3 {
                    font-size: 28px;
                }

                p {
                    font-size: 16px;
                    margin-bottom: 0px;
                }

                .vipImage {
                    width: 33px;
                    height: 33px;
                    right: 10px;
                }
            }
        }
    }
    .package{

        a {
            text-decoration: none;
        }

        .packageHeader {
            .packageTitle {
                font-size: 18px;
                font-weight: 500;
            }
            .packageDetail {
                font-size: 14px;
                text-decoration: none;
                color: $base-color-dark-grey-4;
            }
        }
        .card-white {

            h3 {
                padding: 15px;
                font-size: 26px;
                font-weight: 900;
                border-radius: 5px;
                color: $base-color-white;
                background-color: $base-color-purple;
            }
        }
        .card-gold {

            h3 {
                padding: 15px;
                font-size: 26px;
                font-weight: 900;
                border-radius: 5px;
                color: $base-color-orange;
                background-color: $base-color-purple;
                box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.15);
            }
        }
    }
    .ihsgCard {
        .ihsgCardHeader {
            border-radius: 5px;
            border-left: 15px solid $base-color-purple;

            h3 {
                margin-left: 10px;
            }
        }

        .ihsgCardBody {
            width: 100%;
            height: 180px;
            margin-top: 1rem;
            padding: 1rem;
            border-radius: 5px;
            color: $base-color-black-2;
            background-color: $base-color-purple;
            box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.15);

            h3 {
                color: $base-color-gold;

            }

            .lastUpdate {
                color: $base-color-white;
            }

            p {
                margin-bottom: 0;
            }
        }

        @media (min-width: 300px) and (max-width: 767px) {
            .ihsgCardHeader {
                h3 {
                    font-size: 1.25rem;
                }
            }

            .ihsgCardBody {
                height: auto;
                margin-top: 0px;
                font-size: 20px;

                p {
                    font-size: 11px;
                }
            }

            .ihsgData {
                h3 {
                    font-size: 1.25rem;
                }

                p {
                    margin-bottom: 0;
                }
            }
        }

        @media (min-width: 767px) and (max-width: 1024px) {
            margin-top: 1rem;
        }
    }

    .tableSaham {
        .nav {
            border: 1px solid $base-color-soft-grey-5;
            border-radius: 10px 10px 0px 0px;

            .nav-item {
                width: 50%;
                font-size: 22px;
                text-align: center;

                a {
                    color: $base-color-black-2;
                    font-weight: 500;
                    text-decoration: none;
                    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.15);
                }
                a.active {
                    color: $base-color-white;
                    background-color: $base-color-purple;
                    box-shadow: none;
                }
                a:hover {
                    color: $base-color-white;
                    background-color: $base-color-purple;
                    box-shadow: none;
                }
            }
        }

        .table {
            border: 1px solid $base-color-soft-grey-5;

            thead {
                position: sticky;
                top: 0;
                tr {
                    th {
                        background-color: $base-color-soft-grey-1;
                        border: 1px solid $base-color-soft-grey-5;
                        vertical-align: middle;
                    }
                }
            }
            tbody {
                tr {
                    td {
                        background-color: $base-color-soft-pink;
                        border: 1px solid $base-color-soft-grey-5;

                        &.firstColumn {
                            color: $base-color-green-3;
                        }
                    }
                }
            }
        }

        .table-responsive{
            height: 417px;
            overflow-y: scroll;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
            /* Hide scrollbar for Chrome, Safari and Opera */
            &::-webkit-scrollbar {
                display: none;
            }

        }

        @media (min-width: 300px) and (max-width: 767px) {
            margin-bottom: 50px;

            .nav-item {
                font-size: 14px;
            }

            table {
                background-color: $base-color-white;
                font-size: 10px;
            }

            a {
                background-color: $base-color-white;
                font-size: 12px;
                padding: 5px;
            }
        }
    }

    @media (min-width: 300px) and (max-width: 768px) {
        padding-top: 1rem;
        padding-bottom: 1rem;
        background-color: $base-color-white;
    }
}