@import '_variables.scss';

.sahamTodayArea {
    padding-top: 1rem;
    padding-bottom: 3rem;

    .info {
        h3 {
            color: $base-color-orange;
        }
    }

    .bursa {
        h3 {
            color: $base-color-orange;
        }
        
        .card {
            .card-body {
                color: $base-color-dark-grey;
                background-color: $base-color-dark-grey-3;

                .card-title {
                    font-size: 1.25rem;
                    font-weight: 500;
                }
            }
        }
    }

    .isRegistered {
        form {
            .form-group {
                display: flex;
                padding: 1rem;
                border-radius: 4px;
                justify-content: space-between;
                box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.15);

                .form-label {
                    font-size: 22px;
                    font-weight: 400;
                    margin-bottom: 0;
                }

                .form-control {
                    width: 50px;
                }
            }
        }
    }

    .notRegistered {
        .row {
            justify-content: center;

            img {
                width: 100%;
            }
        }
    }

    @media (min-width: 300px) and (max-width: 768px) {
        padding-bottom: 5rem;

        .bursa {
            .card {
                .card-body {
                    background-color: $base-color-white;
                    font-size: 14px;
                }
            }
        }
    }
}