@import '_variables.scss';

.paketArea {
    .paketAreaCard {
        background-color: $base-color-dark-grey-2;

        .paketAreaHeader {
            font-size: 40px;
            font-weight: 900;
            text-align: center;

            p {
                margin-bottom: 0;
            }
        }

        .paketAreaFooter {
            .boxPricing {
                width: 70%;

                p {
                    font-size: 26px;
                    font-weight: bold;
                    margin-bottom: 0;
                }
            }

            .btn {
                min-width: 180px;
            }
        }
    }

    @media (min-width: 300px) and (max-width: 768px) {
        margin-bottom: 50px;

        .paketAreaFooter {
            .boxPricing {
                width: 80% !important;

                p {
                    font-size: 20px !important;
                }
            }
        }
    }
}

.paketPayment {
    .card {
        border: none;
        background-color: $base-color-soft-pink;
        box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.15);

        .card-header {
            background-color: $base-color-white;
            color: $base-color-black-3;
            border: none;
        }

        .card-body {
            background-color: $base-color-white;
            color: $base-color-black-3;
            border: none;
            margin-top: 10px;

            img {
                width: 95px;
            }
        }

        .linkSalin {
            color: $base-color-orange;
            font-weight: 600;
            cursor: copy;
        }
        .linkSalin.copied {
            color: $base-color-dark-grey;
        }
    }
}