@import '_variables.scss';

.ilmuSahamArea {
    .detail {
        .row {
            a {
                text-decoration: none;
                color: $base-color-black-2;
            }
        }
    }

    .cardVideo {
        //display: flex;
        //flex-wrap: nowrap;
        //overflow: auto;
        
        .card.card-item {
            flex: 1 0 18%;
            margin: 5px;
            width: 219px;
            min-height: 350px;
            color: $base-color-white;
            background-size: cover;

            .card-body {
                display: flex;
                align-items: center;
                min-height: 200px;
                justify-content: center;
                
                img {
                    width: 80px;
                }
            }

            .card-footer {
                border: none;
                text-align: center;
                background-color: transparent;
                white-space: pre-line;
            }

            a {
                text-decoration: none;
                color: $base-color-white;
            }
        }
    }

    .horizontal-menu {
        position: relative;

        .scroll-menu-arrow {
            position: absolute;
            width: 100%;

            .arrow-next,
            .arrow-prev {
                z-index: 2;
                font-size: 2rem;
                color: $base-color-black-3;
                background: $base-color-white;
                width: 50px;
                height: 50px;
                cursor: pointer;
                border-radius: 50px;
                box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.15);
            }

            .arrow-next {
                top: -30px;
                right: -20px;
                position: absolute;
            }

            .arrow-prev {
                top: -30px;
                left: -25px;
                position: absolute;
            }
        }

        @media (min-width: 300px) and (max-width: 767px) {
            .scroll-menu-arrow {
                display: none;
            }
        }
    }

    .listTrending,
    .listNewest,
    .listTopic,
    .listTopicInvestation {
        h3 {
            color: $base-color-orange;
        }

        .notFound {
            width: 100%;
            text-align: center;
        }

        @media (min-width: 300px) and (max-width: 767px) {
            h3 {
                font-size: 1.25rem;
                color: $base-color-black;
            }
        }
    }

    @media (min-width: 300px) and (max-width: 768px) {
        margin-bottom: 3rem;
        background-color: $base-color-white;

        .cardVideo {
            flex-wrap: nowrap;
            overflow: auto;

            .card.card-item {
                flex: 1 0 45%;
                min-height: 280px;
            }

            .card-footer {
                .card-title {
                    font-size: 14px;
                }
            }
        }
    }
}