@import '_variables.scss';

.verification{
    padding: 20px 10px 60px;
    max-width: 800px;
    margin: 0 auto;

    p{
        text-align: center;
        color: $base-color-dark-grey-1;
    }
}