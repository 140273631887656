@import '_variables.scss';
@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");

.vvipArea {
    padding-top: 1rem;
    padding-bottom: 3rem;

    .info {
        h3 {
            color: $base-color-orange;
        }
    }

    .bursa {
        h3 {
            color: $base-color-orange;
        }
        
        .card {
            .card-body {
                color: $base-color-dark-grey;
                background-color: $base-color-dark-grey-3;
            }
        }
    }

    a {
        color: $base-color-black-2;

        &.link-active {
            color: $base-color-orange;
        }
    }

    .accordion {
        .card {
            border: none;
            box-shadow: 0px -1px 15px rgba(0, 0, 0, 0.2);

            .card-header {
                color: $base-color-white;
                transition: 0.2s ease-out;

                &.show::after {
                    content: '\f35b';
                    float: right;
                    font-family: 'Font Awesome 5 Free';
                }
                &.hidden::after {
                    content: '\f358';
                    float: right;
                    font-family: 'Font Awesome 5 Free';
                }


                &.accordion-active {
                    background-color: $base-color-black-2;
                }
                &.accordion-disabled {
                    background-color: $base-color-dark-grey-1;
                }
            }

            .collapse {
                color: $base-color-black-2;

                .card-body {
                    background-color: $base-color-white;
                }
            }
        }
    }

    .isRegistered {
        form {
            .form-group {
                display: flex;
                padding: 1rem;
                border-radius: 4px;
                justify-content: space-between;
                box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.15);
                background-color: $base-color-white;

                .form-label {
                    font-size: 22px;
                    font-weight: 400;
                    margin-bottom: 0;
                }

                .form-control {
                    width: 50px;
                }
            }
        }
    }

    .notRegistered {
        .row {
            justify-content: center;

            img {
                width: 100%;
            }
        }
    }

    .cardVideo {
        display: flex;
        flex-wrap: wrap;

        .card.card-item {
            flex: 1 0 18%;
            margin: 5px;
            width: 219px;
            min-height: 279px;
            color: $base-color-white;
            background-size: cover;

            .card-body {
                display: flex;
                align-items: center;
                min-height: 200px;
                justify-content: center;

                img {
                    width: 92px;
                }
            }

            .card-footer {
                border: none;
                background-color: transparent;
            }

            a {
                text-decoration: none;
                color: $base-color-white;
            }
        }

        .notFound {
            width: 100%;
            text-align: center;
        }
    }

    .listTrending,
    .listNewest,
    .listTopic,
    .listTopicInvestation,
    .listVvipSpecial {
        h3 {
            color: $base-color-orange;
        }

        @media (min-width: 300px) and (max-width: 767px) {
            h3 {
                font-size: 1.25rem;
                color: $base-color-black;
            }
        }
    }

    @media (min-width: 300px) and (max-width: 767px) {
        padding-bottom: 5rem;
        background-color: $base-color-white;

        .bursa {
            .card {
                .card-body {
                    background-color: $base-color-white;
                }
            }
        }

        .cardVideo {
            //flex-wrap: nowrap;
            overflow: auto;

            .card.card-item {
                flex: 1 0 45%;
                min-height: auto;

                .card-body {
                    min-height: 150px;
                }
            }

            .card-footer {
                .card-title {
                    font-size: 14px;
                }
            }
        }

        .accordion {
            margin-top: 10px;
        }

        .listTrending,
        .listNewest,
        .listTopic,
        .listTopicInvestation,
        .listVvipSpecial {
            h3 {
                font-size: 1.25rem;
            }
        }
    }
}
