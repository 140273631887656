@import '_variables.scss';

.profileArea {
    padding-top: 1rem;
    padding-bottom: 3rem;
    min-height: calc(100vh - 200px);
    background-color: $base-color-soft-grey-1;

    .profileSideMenu {
        padding: 0;
        min-height: 600px;
        position: relative;
        color: $base-color-white;
        background-color: $base-color-black-2;

        .pHeader {
            padding: 1rem 2rem;

            img {
                width: 80px;
            }

            .detail {
                h4 {
                    margin-bottom: 0;
                    word-break: break-all;
                }

                p {
                    display: none;
                }
            }

            .iconEdit {
                display: none;
            }
        }

        .pNav {
            margin-top: 1rem;

            a {
                padding: 1rem 2rem;
                font-size: 22px;
                font-weight: 500;
                text-decoration: none;
                color: $base-color-white;
            }

            a.active {
                background-color: $base-color-black;
            }

            a:hover {
                background-color: $base-color-black;
            }
        }

        .btnLogout {
            width: 100%;
            bottom: 2rem;
            border: none;
            text-align: left;
            padding: 1rem 2rem;
            position: absolute;
            background: transparent;
        }
    }

    .profileSideDetail {
        position: relative;
        padding: 2rem 2rem;
        min-height: 600px;
        color: $base-color-black-2;
        background-color: $base-color-white;

        .profileSummary {
            table {
                border-collapse: inherit;
                border-spacing: 0 2em;
            }

            .btnChangeProfil {
                position: absolute;
                bottom: 2rem;
                border-radius: 0;
            }

            .btnDeleteAccount {
                margin-left: 150px;
                position: absolute;
                bottom: 2rem;
                border-radius: 0;
            }
        }

        .fProfileImg {
            img {
                width: 100px;
                height: 100px;
            }

            .fProfileDesc {
                p {
                    font-size: 12px;
                    margin-bottom: 0;
                }
            }
        }

        .profileConfiguration {
            display: none;
        }
    }

    .navPayment {
        .nav-item {
            width: 200px;
            text-align: center;
            background-color: $base-color-soft-grey;

            a {
                color: $base-color-black-3;
                border-radius: 0;
            }

            .active {
                color: $base-color-white;
                background-color: $base-color-orange;
            }
        }
    }

    .inProcess {
        .cardProfilPayment {
            display: flex;
            padding: 1rem;
            background-color: $base-color-soft-grey;

            :hover {
                cursor: pointer;
            }

            .paketDetail {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-around;

                p {
                    margin-bottom: 0;

                    &.title {
                        font-size: 20px;
                        font-weight: 500;
                    }

                    &.price {
                        font-size: 25px;
                        font-weight: 700;
                        color: $base-color-orange;
                    }

                    &.status {
                        font-size: 18px;
                        color: $base-color-red;
                    }
                }

                .iconRight {
                    width: 10px;
                    height: 20px;
                    position: absolute;
                    right: 6%;
                }
            }

            .iconPayment {
                width: 50px;
                height: 50px;
            }
        }

    }

    .formUpdateProfile {
        button {
            width: 150px;
        }
    }

    @media (min-width: 300px) and (max-width: 767px) {
        padding-top: 0;
        padding-bottom: 0;

        .row {
            margin-left: 0;
            margin-right: 0;
        }

        .container {
           padding-left: 0;
            padding-right: 0;
        }

        .profileSummary {
            display: none;
        }

        .profileSideMenu {
            min-height: 100px;

            .pHeader {
                padding: 0.5rem 1rem;

                img {
                    width: 70px !important;
                    border-radius: 10px;
                }

                .detail {

                    h4 {
                        font-size: 16px;
                    }

                    p {
                        display: block;
                        font-size: 12px;
                    }
                }

                .iconEdit {
                    display: block;
                    width: 100%;
                    text-align: center;

                    a {
                        text-decoration: none;
                        color: $base-color-white;
                    }
                }
            }

            .pNav {
                display: none !important;
            }
        }
    
        .profileSideDetail {
            border: none;
            padding: 0;
            height: 100%;

            .title-page {
                display: none;
            }

            .inProcess {
                padding: 0.5rem 1rem;

                .cardProfilPayment {
                    .paketDetail {
                        padding-left: 10px;
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    p {
                        &.title {
                            font-size: 14px;
                        }

                        &.price {
                            font-size: 16px;
                            font-weight: 700;
                            color: $base-color-orange;
                        }

                        &.status {
                            font-size: 12px;
                            color: $base-color-red;
                        }
                    }

                    .iconRight {
                        right: 10%;
                    }
                }
            }

            .fProfileForm {
                order: 2;
                padding-top: 1rem;
            }

            .fProfileImg {
                order: 1;
                padding: 1rem;
                display: flex;
                background-color: $base-color-soft-grey-1;

                img {
                    border-radius: 10px;
                }
            }

            .btn-orange[type="submit"] {
                width: 100%;
            }

            .form-group.footer {
                padding: 1rem;

                .btn-outline-orange {
                    display: none;
                }
            }
        }

        .profileConfiguration {
            padding: 1rem;
            display: block !important;

            .profileAction {
                margin-top: 10px;
                margin-bottom: 10px;
                position: relative;
                background-color: $base-color-white;

                a {
                    text-decoration: none;
                    color: $base-color-black-2;

                    .listAction {
                        padding: 0.5rem 0rem;
                        border-bottom: 1px solid $base-color-soft-grey-2;
                    }
                }

                .iconRight {
                    width: 10px;
                    height: 20px;
                    position: absolute;
                    right: 10px;
                }
            }

            .paketStatus {
                padding: 1rem;
                border: none;
                margin-top: 1rem;
                border-radius: 4px;
                flex-direction: row;
                justify-content: space-between;
                box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);

                .detail {
                    p{
                        margin-bottom: 0;
                        font-size: 14px;

                        &.title {
                            font-weight: 500;
                        }

                        &.limitDate {
                            font-weight: 500;
                            color: $base-color-red;
                        }
                    }
                }

                .btnRenewal {
                    justify-content: center;

                    .btn-outline-orange {
                        display: block;
                    }
                }
            }
        }
    }
}

.modalPaymentDetail {
    .modal-body {
        padding: 3rem;

        .wrapper {
            padding: 3rem;
            background-color: $base-color-soft-pink;

            .title {
                color: $base-color-black-3;
                font-size: 14px;
                margin-bottom: 0;
            }

            .detail {
                color: $base-color-black;
                font-weight: 500;
                font-size: 16px;
            }

            .status {
                color: $base-color-soft-orange;
                font-weight: 700;
            }
        }

        .logo {
            img {
                width: 150px;
            }
        }
    }

    @media (min-width: 300px) and (max-width: 767px) {
        .modal-body {
            padding: 1rem;
            word-break: break-word;

            .wrapper {
                padding: 1rem;

                .title {
                    font-size: 12px;
                }

                .detail {
                    font-size: 14px;
                }
            }
        }
    }
}