@import '_variables.scss';

.AuthPage {
    min-height: 100vh;
    background-color: $base-color-soft-grey-1;

    .container {
        padding-top: 3rem;
        padding-bottom: 5rem;
    }

    .walkthrough {
        padding: 3em;
        height: 820px;
        color: $base-color-black-3;
        background-color: $base-color-white;

        .walkthroughBody {
            img {
                width: 100%;
            }
        }

        .walkthroughFooter {
            position: absolute;
            bottom: 2rem;
            left: 0;
            right: 0;

            button {
                width: 18px;
                height: 18px;
                margin: 10px;
                border: none;
                border-radius: 100%;
            }
        }
    }

    .loginForm,
    .registerForm {
        padding: 3rem;
        height: 820px;
        color: $base-color-black-3;
        background-color: $base-color-white;
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
          }

        .loginFormBody,
        .registerForm {

            .linkForgotPassword {
                text-decoration: none;
                color: $base-color-soft-orange;
            }
        }

        .loginFormOr {
            display: flex; 
            flex-direction: row; 

            p {
                margin-bottom: 0;
                margin: 20px;
            }
        }
        .loginFormOr:before, 
        .loginFormOr:after { 
            content: ""; 
            flex: 1 1; 
            border-bottom: 2px solid $base-color-black-3; 
            margin: auto; 
        } 

        .loginFormFooter {
            .linkRegister {
                color: $base-color-dark-grey-1;

                a {
                    text-decoration: none;
                    color: $base-color-soft-orange;
                }
            }
        }
    }

    @media (min-width: 300px) and (max-width: 767px) {
        padding: 0;

        .container {
            padding-top: 1rem;
        }

        .walkthrough {
            padding: 2rem;
            height: 650px;
    
            .walkthroughBody {
                img {
                    width: 100%;
                }
            }
        }

        .loginForm,
        registerForm {
            padding: 2rem;
            height: 650px;
            margin-top: 1rem;
        }

        .registerForm {
            padding: 1rem;
            height: 650px;
        }
    }
}