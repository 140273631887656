// global variable
$base-bg-white: #ececec;
$base-color-white: #ffffff;
$base-color-black: #000000;
$base-color-black-2: #263238;
$base-color-black-3: #474747;
$base-color-soft-orange: #282B50;
$base-color-orange: #DAC047;
$base-color-orange-2: #AD924B;
$base-color-purple-2: #242843;
$base-color-purple: #282B50;
$base-color-soft-grey: #EBEBEC;
$base-color-soft-grey-1: #E5E5E5;
$base-color-soft-grey-2: #D8D8D8;
$base-color-soft-grey-3: #EBEBEB;
$base-color-soft-grey-4: #959595;
$base-color-soft-grey-5: #ABABC9;
$base-color-dark-grey: #263238;
$base-color-dark-grey-1: #979797;
$base-color-dark-grey-2: #F4F4F4;
$base-color-dark-grey-3: #F0EEEE;
$base-color-dark-grey-4: #9C9C9C;
$base-color-dark-grey-5: #DCDCDC;
$base-color-dark-black: #1E2133;
$base-color-blue: #3B579D;
$base-color-soft-pink: #F5F5FA;
$base-color-red: #FF0000;
$base-color-gold: #DAC047;
$base-color-green: #00C800;
$base-color-green-2: #4CA94C;
$base-color-green-3: #196B19;

// global style
.btn-orange {
    color: $base-color-white;
    background-color: $base-color-orange;

    &:hover {
        color: $base-color-white;
    }
}

.btn-outline-orange {
    color: $base-color-orange;
    background-color: $base-color-white;
    border: 1px solid $base-color-orange;

    &:hover {
        color: $base-color-orange;
    }
}

.btn-transparent-orange {
    color: $base-color-orange;
    background-color: transparent;
    border: none;

    &:hover {
        color: $base-color-orange;
    }
}

.btn-dark-grey {
    color: $base-color-white;
    background-color: $base-color-dark-grey;

    &:hover {
        color: $base-color-white;
    }
}

.btn-soft-grey {
    color: $base-color-dark-grey-3;
    background-color: $base-color-soft-grey-2;

    &:hover {
        color: $base-color-white;
    }
}

.btn-facebook {
    width: 100%;
    display: flex;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    color: $base-color-white;
    background-color: $base-color-blue;

    img {
        width: 37px;
        height: 37px;
        margin-right: 5px;
    }

    &:hover {
        color: $base-color-white;
    }

    @media (min-width: 300px) and (max-width: 768px) {
        font-size: 18px;
    }
}

.btn-google {
    width: 100%;
    display: flex;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    color: $base-color-black-3;
    background-color: $base-color-white;
    border: 1.5px solid $base-color-dark-grey-1;

    img {
        width: 37px;
        height: 37px;
        margin-right: 5px;
    }

    &:hover {
        color: $base-color-black-3;
    }

    @media (min-width: 300px) and (max-width: 768px) {
        font-size: 18px;
    }
}

.box-orange {
    border: none;
    color: $base-color-white;
    background-color: $base-color-orange;

    &:hover {
        color: $base-color-white;
        background-color: $base-color-orange;
    }
}

.box-dark-grey {
    border: none;
    color: $base-color-white;
    background-color: $base-color-dark-grey;

    &:hover {
        color: $base-color-white;
        background-color: $base-color-dark-grey;
    }
}